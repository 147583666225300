import React from 'react'

const LinkedInIcon = (props) => (
  <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
      <rect width="30" height="30" rx="7" fill="#fff"/>
    </mask>
    <g mask="url(#mask0)">
      <path 
        d="M27.7854 0H2.21458C1.62724 0 1.06395 0.233322 0.648637 0.648637C0.233322 1.06395 0 1.62724 0 2.21458V27.7854C0 28.3728 0.233322 28.936 0.648637 29.3514C1.06395 29.7667 1.62724 30 2.21458 30H27.7854C28.3728 30 28.936 29.7667 29.3514 29.3514C29.7667 28.936 30 28.3728 30 27.7854V2.21458C30 1.62724 29.7667 1.06395 29.3514 0.648637C28.936 0.233322 28.3728 0 27.7854 0ZM8.94167 25.5562H4.43125V11.2292H8.94167V25.5562ZM6.68333 9.24375C6.1717 9.24087 5.67239 9.08649 5.24842 8.80011C4.82444 8.51372 4.4948 8.10816 4.3011 7.63461C4.10739 7.16106 4.05831 6.64074 4.16004 6.13932C4.26177 5.6379 4.50975 5.17785 4.87269 4.81723C5.23564 4.45661 5.69727 4.21158 6.19933 4.11308C6.7014 4.01457 7.22139 4.06699 7.69369 4.26373C8.16599 4.46047 8.56942 4.79271 8.85308 5.21852C9.13674 5.64432 9.2879 6.14461 9.2875 6.65625C9.29233 6.99879 9.22814 7.3388 9.09876 7.65601C8.96939 7.97321 8.77748 8.26112 8.53446 8.50258C8.29145 8.74404 8.00231 8.9341 7.68428 9.06144C7.36625 9.18877 7.02584 9.25078 6.68333 9.24375ZM25.5667 25.5687H21.0583V17.7417C21.0583 15.4333 20.0771 14.7208 18.8104 14.7208C17.4729 14.7208 16.1604 15.7292 16.1604 17.8V25.5687H11.65V11.2396H15.9875V13.225H16.0458C16.4813 12.3438 18.0063 10.8375 20.3333 10.8375C22.85 10.8375 25.5687 12.3313 25.5687 16.7063L25.5667 25.5687Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default LinkedInIcon